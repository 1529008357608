// export const baseUrl = "http://localhost:8000/api/v1";
// export var baseUrl: any;

// if (process.env.NODE_ENV === "development") {
//   baseUrl = process.env.REACT_APP_BASE_URL_LOCAL;
// } else {
//   baseUrl = process.env.REACT_APP_BASE_URL;
// }

// export const baseUrl = "https://begaltest1.onrender.com/api/v1";
// export const baseUrl = "http://18.206.213.140/api/v1";


// export const baseUrl = "http://3.223.100.152/api/v1";
// export const baseUrl = "http://3.223.100.152/api/v1";
export const baseUrl = "https://mlbagels.com//api/v1";
