import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Typography,
  Grid,
  Paper,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { Line } from "react-chartjs-2";
import "chart.js/auto"; // for Chart.js
import { baseUrl } from "utils/baseUrl";
import { enUS } from "date-fns/locale";
import FilterListIcon from "@mui/icons-material/FilterList";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { GridColDef } from "@mui/x-data-grid";

import { DateRangePicker } from "@umakantp/react-date-range";
import "react-date-range/dist/styles.css"; // Basic styles
import "react-date-range/dist/theme/default.css"; // Theme styles
import ReusableTable from "Components/Table/Table";

export default function Dashboard() {
  const [dashboardData, setDashboardData] = useState(null);
  const [open, setOpen] = useState(false); // State for dialog
  const [ordersData, setOrdersData] = useState([]);

  const [searchText, setSearchText] = useState("");

  const [state, setState] = useState([
    {
      // startDate: new Date(),
      startDate: new Date(new Date().setDate(new Date().getDate() - 7)),
      endDate: new Date(),
      key: "selection",
    },
  ]);

  const token = localStorage.getItem("userToken");

  // Fetch dashboard data based on the date range
  const fetchDashboardData = (startDate: Date, endDate: Date) => {
    axios
      .get(`${baseUrl}/orders_and_revenue`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          start_date: startDate.toISOString().split("T")[0], // Format as YYYY-MM-DD
          end_date: endDate.toISOString().split("T")[0],
        },
      })
      .then((response) => {
        setDashboardData(response.data);
      })
      .catch((error) => {
        console.error("There was an error fetching the data!", error);
      });
  };

  useEffect(() => {
    // Fetch data with initial default range when component mounts
    const { startDate, endDate } = state[0];
    fetchDashboardData(startDate, endDate);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchOrdersData = (startDate: any, endDate: any) => {
    const token = localStorage.getItem("userToken");
    axios
      .get(`${baseUrl}/orders_statistics`, {
        headers: { Authorization: `Bearer ${token}` },
        params: {
          start_date: startDate.toISOString().split("T")[0],
          end_date: endDate.toISOString().split("T")[0],
        },
      })
      .then((response) => {
        setOrdersData(response.data);
      })
      .catch((error) => console.error("Error fetching orders", error));
  };

  useEffect(() => {
    const { startDate, endDate } = state[0];
    fetchOrdersData(startDate, endDate);
  }, [state]);

  const handleClickOpen = () => {
    setOpen(true); // Open dialog
  };

  const handleClose = () => {
    setOpen(false); // Close dialog
  };

  const handleApplyDateRange = () => {
    const { startDate, endDate } = state[0];
    fetchDashboardData(startDate, endDate); // Fetch data based on custom range
    setOpen(false); // Close dialog after applying filter
  };

  if (!dashboardData) {
    return <Typography>Loading...</Typography>;
  }

  const {
    total_orders,
    total_revenue,
    revenue_by_payment_type,
    daily_stats,
    daily_revenue,
    orders_percentage_difference,
    revenue_percentage_difference,
  } = dashboardData;

  const getWeekDay = (dateStr: any) => {
    const days = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    const date = new Date(dateStr);
    return days[date.getDay()];
  };

  const dailyOrderLabels = Object.keys(daily_stats).map((date) =>
    getWeekDay(date)
  );
  const dailyRevenueLabels = Object.keys(daily_revenue).map((date) =>
    getWeekDay(date)
  );

  const dailyOrderData = {
    labels: dailyOrderLabels,
    datasets: [
      {
        label: "Orders",
        data: Object.values(daily_stats).map((value) => value || 0),
        borderColor: "rgba(75, 192, 192, 1)",
        fill: false,
      },
    ],
  };

  const dailyRevenueData = {
    labels: dailyRevenueLabels,
    datasets: [
      {
        label: "Revenue",
        data: Object.values(daily_revenue),
        borderColor: "rgba(54, 162, 235, 1)",
        fill: false,
      },
    ],
  };

  const options = {
    scales: {
      y: {
        ticks: {
          callback: function (value: any) {
            return "$" + value.toFixed(2);
          },
        },
      },
    },
  };

  const columns: GridColDef[] = [
    { field: "order_type", headerName: "Order Type", flex: 1 },
    { field: "payment_type", headerName: "Payment Type", flex: 1 },
    // { field: "total_cost", headerName: "Total Cost", flex: 1 },
    { 
      field: "total_cost", 
      headerName: "Total Cost", 
      flex: 1,
      renderCell: (params) => `$${parseFloat(params.value).toFixed(2)}` // Add this line to format total_cost with $
    },
    { field: "date", headerName: "Date", flex: 1 },
    { field: "time", headerName: "time", flex: 1 },
    // { field: "notes", headerName: "Notes", flex: 1 },
  ];

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(event.target.value);
  };

  const filteredRows = ordersData
    .filter((order) => {
      const searchLower = searchText.toLowerCase();
      return (
        order.order_type?.toLowerCase().includes(searchLower) ||
        order.payment_type?.toLowerCase().includes(searchLower) ||
        // order.notes?.toLowerCase().includes(searchLower) ||
        order.date?.toLowerCase().includes(searchLower) ||
        order.time?.toLowerCase().includes(searchLower) ||
        order.total_cost?.toString().includes(searchLower)
      );
    })
    .map((order) => ({
      id: order.id,
      order_type: order.order_type ?? "N/A",
      payment_type: order.payment_type ?? "N/A",
      total_cost: order.total_cost ?? "N/A",
      date: order.date ?? "N/A",
      time: order.time ?? "N/A",
      // notes: order.notes ?? "N/A",
    }));

  return (
    <Grid container spacing={3}>
      {/* Filter Button */}
      <Grid item xs={12}>
        <Button
          aria-controls="simple-menu"
          aria-haspopup="true"
          variant="contained"
          onClick={handleClickOpen}
          style={{ marginBottom: 20 }}
          // size="small"
          startIcon={<FilterListIcon />}
          endIcon={<ArrowDropDownIcon />}
        >
          Filter
        </Button>

        {/* Dialog for DateRangePicker */}
        <Dialog open={open} onClose={handleClose} maxWidth="lg" fullWidth>
          <DialogTitle>Choose Date Range</DialogTitle>
          <DialogContent sx={{ margin: "auto" }}>
            <DateRangePicker
              onChange={(item) => {
                const { selection } = item;
                if (selection.startDate && selection.endDate) {
                  setState([
                    {
                      startDate: selection.startDate || new Date(),
                      endDate: selection.endDate || new Date(),
                      key: selection.key,
                    },
                  ]);
                }
              }}
              moveRangeOnFirstSelection={false}
              months={2}
              ranges={state}
              direction="horizontal"
              locale={enUS}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
            <Button onClick={handleApplyDateRange} color="primary">
              Apply
            </Button>
          </DialogActions>
        </Dialog>
      </Grid>

      {/* Total Revenue */}
      <Grid item xs={12} sm={6} md={3}>
        <Paper elevation={3} style={{ padding: 20, minHeight: "150px" }}>
          <Typography variant="h5">Total Revenue</Typography>
          <Typography variant="h4">
            ${parseFloat(total_revenue).toFixed(2)}
          </Typography>
          <Typography variant="subtitle1">
            <span
              style={{
                color: revenue_percentage_difference >= 0 ? "green" : "red",
              }}
            >
              {revenue_percentage_difference >= 0 ? "+" : ""}
              {revenue_percentage_difference}%
            </span>
            <span style={{ color: "gray" }}> from last week</span>
          </Typography>
        </Paper>
      </Grid>

      {/* Total Orders */}
      <Grid item xs={12} sm={6} md={3}>
        <Paper elevation={3} style={{ padding: 20, minHeight: "150px" }}>
          <Typography variant="h5">Total Orders</Typography>
          <Typography variant="h4">{total_orders}</Typography>
          <Typography variant="subtitle1">
            <span
              style={{
                color: orders_percentage_difference >= 0 ? "green" : "red",
              }}
            >
              {orders_percentage_difference >= 0 ? "+" : ""}
              {orders_percentage_difference}%
            </span>
            <span style={{ color: "gray" }}> from last week</span>
          </Typography>
        </Paper>
      </Grid>

      {/* Cash Orders Value */}
      <Grid item xs={12} sm={6} md={3}>
        <Paper elevation={3} style={{ padding: 20, minHeight: "150px" }}>
          <Typography variant="h5">Cash Orders Value</Typography>
          <Typography variant="h4">
            ${parseFloat(revenue_by_payment_type.cash).toFixed(2)}
          </Typography>
        </Paper>
      </Grid>

      {/* Card Orders Value */}
      <Grid item xs={12} sm={6} md={3}>
        <Paper elevation={3} style={{ padding: 20, minHeight: "150px" }}>
          <Typography variant="h5">Card Orders Value</Typography>
          <Typography variant="h4">
            ${parseFloat(revenue_by_payment_type.visa).toFixed(2)}
          </Typography>
        </Paper>
      </Grid>

      {/* Total Revenue Over Time (Chart) */}
      <Grid item xs={12} md={6}>
        <Paper elevation={3} style={{ padding: 20 }}>
          <Typography variant="h5">Total Revenue</Typography>
          <Line data={dailyRevenueData} options={options} />
        </Paper>
      </Grid>

      {/* Total Orders Over Time (Chart) */}
      <Grid item xs={12} md={6}>
        <Paper elevation={3} style={{ padding: 20 }}>
          <Typography variant="h5">Total Orders</Typography>
          <Line data={dailyOrderData} />
        </Paper>
      </Grid>

      <Grid item xs={12}>
        <ReusableTable
          searchText={searchText}
          onSearchChange={handleSearchChange}
          // onAddButtonClick={handleOpenDialog}
          rows={filteredRows}
          columns={columns}
          // loading={isLoading}
          // addButtonName="Add New "
        />
      </Grid>
    </Grid>
  );
}
